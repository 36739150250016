import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { importSpreadsheet } from "../../components/Api/Post";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
	getLDAPUserBySearchField,
	checkInstructorResultsEmail,
	sendInstructorResultsEmail,
	checkMidtermEmails,
	sendMidtermEmails,
} from "../../components/Api/Getters";
import { populateQPUsers } from "../../components/Api/Post";
import { CircularProgress, TextField } from "@mui/material";
import AutocompleteComponent from "../../components/Utils/AutocompleteComponent";
import { useDepartmentsQuery } from "../../Hooks/useDepartmentsQuery";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const tableOptions = [
	{
		name: "COURSE_STUDENT",
		value: "COURSE_STUDENT",
		type: "relationship",
	},
	{
		name: "COURSE_INSTRUCTOR",
		value: "COURSE_INSTRUCTOR",
		type: "relationship",
	},
];

const searchFields = [
	{
		name: "CCID",
		value: "uid",
	},
	{
		name: "EMPID",
		value: "employeeNumber",
	},
];

export default function AdminUtils() {
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [csvData, setCsvData] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const auth = isAuthenticated();
	const token = auth.token;
	const { data: departments } = useDepartmentsQuery(token);
	const [loading, setLoading] = useState(false);
	const [importStatus, setImportStatus] = useState("");
	const [searchStatus, setSearchStatus] = useState("");
	const [populateQPLoading, setPopulateQPLoading] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");

	const [classid, setClassid] = useState(null);
	const [deptId, setDeptId] = useState(null);
	useEffect(() => {
		if (autocompleteValue) {
			const deptid = autocompleteValue.key;
			setDeptId(deptid);
		}
	}, [autocompleteValue, setDeptId]);
	// const handleEmailInstructorsByClassid = async () => {
	// 	try {
	// 		const response = await postEmailInstructorByClassId(token, classid);
	// 		console.log(response);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	const handlePopulateQP = async () => {
		try {
			setPopulateQPLoading(true);
			setStatusMessage("Populating QP Users, please wait...");
			const response = await populateQPUsers(token);
			if (response.status === 200) {
				setPopulateQPLoading(false);
				setStatusMessage("QP Users populated successfully");
			}
		} catch (error) {
			setPopulateQPLoading(false);
			setStatusMessage("Error populating QP Users", error);
			console.error(error);
		}
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];

		if (file) {
			Papa.parse(file, {
				header: true,
				skipEmptyLines: true,
				complete: (result) => {
					// Extracting headers and data
					setHeaders(Object.keys(result.data[0] || {}));
					setCsvData(result.data);
				},
				error: (error) => {
					console.error("Error reading CSV file:", error);
				},
			});
		}
	};

	const handleSelectChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const handleImport = async () => {
		const data = {
			table: selectedOption,
			data: csvData,
		};

		try {
			setLoading(true);
			setImportStatus("Importing spreadsheet, please wait...");
			const response = await importSpreadsheet(token, data);
			if (response.status === 200) {
				setLoading(false);
				setImportStatus("Spreadsheet imported successfully");
			}
		} catch (error) {
			setLoading(false);
			setImportStatus("Error importing spreadsheet", error);
			console.error(error);
		}
	};

	const [searchFieldValue, setSearchFieldValue] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [instructorsForEmail, setInstructorsForEmail] = useState([]);
	const handleSearchChange = (event) => {
		setSearchFieldValue(event.target.value);
	};

	const handleLDAPSearch = async () => {
		try {
			const response = await getLDAPUserBySearchField(
				token,
				searchFieldValue,
				searchValue
			);

			if (!response || response.length === 0) {
				// Handle the case where the response is empty/null
				setSearchStatus("User not found in LDAP.");
				return;
			}

			// Update search results by appending the new response if the user doesn't already exist
			setSearchResults((prevSearchResults) => {
				// Check if the user is already in the search results
				const userExists = prevSearchResults.some(
					(user) => user.empid === response.empid
				);

				// If user does not exist, add the response to the search results
				if (!userExists) {
					return [...prevSearchResults, response];
				}

				// If user already exists, return the previous results without modification
				return prevSearchResults;
			});

			//console.log(searchResults);
		} catch (error) {
			// Handle the error, such as showing an alert or logging it
			setSearchStatus("Error searching for user");
			console.error("Search error:", error);
		}
	};

	const handleCheckInstructorResultsEmail = async () => {
		try {
			const response = await checkInstructorResultsEmail(token, deptId);
			//console.log(response);
			if (response.data_classes && response.data_classes.length > 0) {
				console.log(response.data_classes);
				setInstructorsForEmail(response.data_classes);
			} else {
				console.log(response);
			}
		} catch (error) {
			console.error(error);
		}
	};

	//console.log(instructorsForEmail);

	const [loadingResultsEmails, setLoadingResultsEmails] = useState(false);

	const handleSendEmails = async () => {
		try {
			setLoadingResultsEmails(true);
			const response = await sendInstructorResultsEmail(token, deptId);
			// check again
			const response2 = await checkInstructorResultsEmail(token, deptId);
			if (response2.data_classes && response2.data_classes.length > 0) {
				setInstructorsForEmail(response2.data_classes);
			} else {
				setInstructorsForEmail([]);
			}
			setLoadingResultsEmails(false);
			console.log(response);
		} catch (error) {
			console.error(error);
			setLoadingResultsEmails(false);
		}
	};

	console.log(deptId);

	const [midtermEmailInstructors, setMidtermEmailInstructor] = useState([]);

	const handleCheckMidtermEmails = async () => {
		try {
			const response = await checkMidtermEmails(token);
			console.log(response);
			if (response.instructors && response.instructors.length > 0) {
				console.log(response.instructors);
				setMidtermEmailInstructor(response.instructors);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const [loadingMidterm, setLoadingMidterm] = useState(false);
	const [midtermSent, setMidtermSent] = useState(false);
	const handleSendMidtermEmails = async () => {
		try {
			setLoadingMidterm(true);
			const response = await sendMidtermEmails(token);
			console.log(response);
			setLoadingMidterm(false);
			setMidtermSent(true);
		} catch (error) {
			console.error(error);
			setLoadingMidterm(false);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab
						label="CSV Uploads"
						{...a11yProps(0)}
					/>
					<Tab
						label="LDAP Search"
						{...a11yProps(1)}
					/>
					<Tab
						label="Populate QP"
						{...a11yProps(2)}
					/>
					<Tab
						label="Email Instructor Results"
						{...a11yProps(3)}
					/>
					<Tab
						label="Midterm Email"
						{...a11yProps(4)}
					/>
				</Tabs>
			</Box>
			<CustomTabPanel
				value={value}
				index={0}
			>
				<div className="w-full flex flex-col items-center justify-center mt-8 pb-10">
					<input
						className="border-2 rounded-md px-4 py-2 bg-gray-100 text-black border-green-800 mb-4"
						type="file"
						accept=".csv"
						onChange={handleFileUpload}
					/>

					<select
						value={selectedOption}
						onChange={handleSelectChange}
						className="mb-4 px-4 py-2 border border-gray-400 rounded-md text-gray-700"
					>
						<option
							value=""
							disabled
						>
							Select Table Option
						</option>
						{tableOptions.map((option) => (
							<option
								key={option.value}
								value={option.value}
							>
								{option.name}
							</option>
						))}
					</select>

					{selectedOption && (
						<button
							onClick={handleImport}
							disabled={loading || csvData.length === 0}
							className={`${
								loading || csvData.length === 0
									? " bg-gray-500 text-black border-gray-800 hover:cursor-not-allowed"
									: "bg-green-700 hover:bg-green-800 text-white border-green-800"
							}border-2 rounded-md px-4 py-2   `}
						>
							Import {csvData.length} rows Into {selectedOption}
						</button>
					)}

					{headers.length > 0 && csvData.length > 0 && (
						<div className="mt-4 max-h-[60dvh] overflow-y-auto w-[50dvw] ">
							<table className="table-auto border-collapse border border-gray-400 w-full">
								<thead>
									<tr>
										{headers.map((header, index) => (
											<th
												key={index}
												className="border border-gray-400 px-4 py-2"
											>
												{header}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{csvData.map((row, rowIndex) => (
										<tr key={rowIndex}>
											{headers.map((header, index) => (
												<td
													key={index}
													className="border border-gray-400 px-4 py-2"
												>
													{row[header]}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={1}
			>
				<div className="w-full flex flex-col items-center justify-center mt-8 pb-10">
					<select
						className="mb-4 px-4 py-2 border border-gray-400 rounded-md text-gray-700"
						onChange={handleSearchChange}
						value={searchFieldValue}
					>
						<option
							value=""
							disabled
						>
							Select Search Field
						</option>
						{searchFields.map((option) => (
							<option
								key={option.value}
								value={option.value}
							>
								{option.name}
							</option>
						))}
					</select>
					<input
						className="border-2 rounded-md px-4 py-2 bg-gray-100 text-black border-green-800 mb-4"
						type="text"
						onChange={(e) => setSearchValue(e.target.value)}
						placeholder="Search Value"
					/>
					<button
						disabled={!searchFieldValue || !searchValue}
						onClick={handleLDAPSearch}
						className={`disabled:bg-gray-400 disabled:border-gray-500 bg-green-700 hover:bg-green-800 text-white border-green-800 border-2 rounded-md px-4 py-2`}
					>
						Search
					</button>
					{searchStatus && <div className="text-red-500">{searchStatus}</div>}
					<div className="mt-4 max-h-[60dvh] overflow-y-auto w-[50dvw] ">
						<table className="table-auto border-collapse border border-gray-400 w-full">
							<thead>
								<tr>
									<th className="border border-gray-400 px-4 py-2">CCID</th>
									<th className="border border-gray-400 px-4 py-2">EMPID</th>
									<th className="border border-gray-400 px-4 py-2">
										First Name
									</th>
									<th className="border border-gray-400 px-4 py-2">
										Last Name
									</th>
									<th className="border border-gray-400 px-4 py-2">Email</th>
								</tr>
							</thead>
							<tbody>
								{searchResults &&
									searchResults.map((row, rowIndex) => (
										<tr key={rowIndex}>
											<td className="border border-gray-400 px-4 py-2">
												{row.ccid}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.empid}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.firstName}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.lastName}
											</td>
											<td className="border border-gray-400 px-4 py-2">
												{row.email}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={2}
			>
				<button
					onClick={handlePopulateQP}
					className="px-4 py-2 bg-gray-200 border-gray-400 border-2 rounded-xl hover:bg-gray-600 hover:border-gray-800 hover:text-white"
				>
					Populate QP Users
				</button>
				{populateQPLoading && <div>{statusMessage}</div>}
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={3}
			>
				{/* <TextField label="Class ID" value={classid} onChange={(e) => setClassid(e.target.value)} /> */}
				<div className="flex gap-2 mb-2">
					<AutocompleteComponent
						options={departments ? departments : []}
						variant="outlined"
						marginTop="0px"
						marginBottom="0px"
						value={autocompleteValue}
						setValue={setAutocompleteValue}
						inputValue={autocompleteInputValue}
						setInputValue={setAutocompleteInputValue}
						label="Department"
						autoHighlight={true}
					/>
					<button
						onClick={handleCheckInstructorResultsEmail}
						disabled={!deptId || deptId === null || loadingResultsEmails}
						className={`${
							(!deptId || deptId === null) &&
							"disabled:hover:cursor-not-allowed disabled:bg-gray-400 disabled:border-gray-500 "
						} px-4 py-2 bg-green-700 border-green-800 border-2 rounded-xl hover:bg-green-900 hover:border-gray-800 text-white mb-2`}
					>
						Check Results Invitations
					</button>
					{instructorsForEmail.length > 0 && (
						<button
							onClick={handleSendEmails}
							className="px-4 ml-2 py-2 bg-green-700 border-green-800 border-2 rounded-xl hover:bg-green-900 hover:border-gray-800 text-white mb-2"
						>
							Send Emails to {instructorsForEmail.length} Instructors
						</button>
					)}
				</div>

				<p className="px-4 py-2 bg-gray-200 border-gray-400 border-2 rounded-xl mb-2">
					Total Instructors: {instructorsForEmail.length}
				</p>
				<div className="flex flex-col gap-1 w-[300px] bg-gray-300 rounded-xl p-2">
					<div className="flex justify-between ">
						<div className=" font-medium">Classes</div>
						<div className=" font-medium">Email</div>
					</div>
					<div className="h-[1px] w-full bg-gray-900"></div>
					{instructorsForEmail.map((instructor) => (
						<div
							key={instructor.empid}
							className="flex justify-between"
						>
							<div
								className=""
								title={instructor.classes.map((item) => item.classid).join(",")}
							>
								{instructor.classes.length}
							</div>
							<div className="">{instructor.email}</div>
						</div>
					))}
				</div>
				{/* {populateQPLoading && <div>{statusMessage}</div>} */}
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={4}
			>
				{/* <TextField label="Class ID" value={classid} onChange={(e) => setClassid(e.target.value)} /> */}
				<div className="flex gap-2 mb-2">
					<button
						onClick={handleCheckMidtermEmails}
						className={`px-4 py-2 bg-green-700 border-green-800 border-2 rounded-xl hover:bg-green-900 hover:border-gray-800 text-white mb-2`}
					>
						Check Midterm Emails
					</button>
					{}
					{midtermEmailInstructors.length > 0 && (
						<button
							onClick={handleSendMidtermEmails}
							disabled={loadingMidterm || midtermSent}
							className={`${
								midtermSent &&
								"disabled:bg-gray-400  disabled:border-gray-800 hover:cursor-not-allowed"
							} px-4 ml-2 py-2 bg-green-700 border-green-800 border-2 rounded-xl hover:bg-green-900 hover:border-gray-800 text-white mb-2 `}
						>
							{loadingMidterm ? (
								<CircularProgress />
							) : (
								"Send Midterm Emails to " +
								midtermEmailInstructors.length +
								" Instructors"
							)}
						</button>
					)}
				</div>

				<p className="px-4 py-2 bg-gray-200 border-gray-400 border-2 rounded-xl mb-2">
					Total Instructors: {midtermEmailInstructors.length}
				</p>
				<div className="flex flex-col gap-1 w-[300px] bg-gray-300 rounded-xl p-2">
					<div className="flex justify-between ">
						<div className=" font-medium">Classes</div>
						<div className=" font-medium">Email</div>
					</div>
					<div className="h-[1px] w-full bg-gray-900"></div>
					{midtermEmailInstructors.map((instructor) => (
						<div
							key={instructor.empid}
							className="flex justify-between"
						>
							<div
								className=""
								//title={instructor.classes.map((item) => item.classid).join(",")}
							>
								{instructor.name}
							</div>
							<div className="">{instructor.ccid}@ualberta.ca</div>
						</div>
					))}
				</div>
				{/* {populateQPLoading && <div>{statusMessage}</div>} */}
			</CustomTabPanel>
		</Box>
	);
}
